export const muteMiddleware = (store) => (next) => (action) => {
  const { isMuted, player } = store.getState();
  const result = next(action);
  const nextState = store.getState();
  const { isMuted: newIsMuted } = nextState;
  if (player && isMuted !== newIsMuted) {
    player.muted = newIsMuted;
  }
  return result;
};
