import { thunkMiddleware } from './middlewares/thunkMiddleware.js';
import { playerMiddleware } from './middlewares/playerMiddleware.js';
import { loggerMiddleware } from './middlewares/loggerMiddleware.js';
import { seekMiddleware } from './middlewares/seekMiddleware.js';
import { adploggerSdkMiddleware } from './middlewares/adpLoggerSdkMiddleware.js';
import { loadPlayerMiddleware } from './middlewares/loadPlayerMiddleware.js';
import { mediaIdMiddleware } from './middlewares/mediaIdMiddleware.js';
import { adMiddleware } from './middlewares/adMiddleware.js';
import { muteMiddleware } from './middlewares/muteMiddleware.js';

export { default as createStore } from './store.js';
export { default as applyMiddleware } from './applyMiddleware.js';
export { actions } from './actions.js';
export { rootReducer } from './reducers.js';
export const middlewares = [
  thunkMiddleware,
  playerMiddleware,
  seekMiddleware,
  mediaIdMiddleware,
  muteMiddleware,
  loadPlayerMiddleware,
  loggerMiddleware,
  adploggerSdkMiddleware,
  adMiddleware,
];

export { default as createReactiveProperties } from './reactiveProperties.js';
export { types, DEFAULT_PLAYER_ID } from './types.js';
